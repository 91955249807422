<template>
  <div class="details-page">
    <div class="btns">
      <button @click="secede">返回</button>
    </div>
    <!-- <p class="center" v-html="this.myInfo"></p> -->
    <div class="center">
    <template v-if="fileUrl">
        <iframe 
          :src="fileUrl + '#toolbar=0'"
          frameborder="0"
          type="application/x-google-chrome-pdf"
          scrolling="auto"
          :style="`width: 100%; height: 800px`">
        </iframe>
      </template>
      </div>
  </div>
</template>

<script>
// import { getFileDetail, getHRDetail } from "../../../FackApi/api/index";
import { getFileUrl, getHRDetail } from "../../../FackApi/api/admin";
export default {
  name: "detailsPage",
  components: {},
  data() {
    return {
      myInfo: "",
      fileUrl: "",
    };
  },
  created() {},
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    // async getFileDetailInfo(info) {
    //   let res = await getFileDetail(info);
    //   if (res.code == 0) {
    //     this.myInfo = res.data.content;
    //   }
    // },
    async getHRDetailInfo(info) {
      // let res = await getHRDetail(info.id);
      // if (res.code == 0) {
      //   this.myInfo = res.data.content;
      // }
      let params = {
        expireTime: 3600,
        objectName: info.handledFilePath,
        BucketName: info.buketName,
        EndPoint: 'https://oss-cn-shenzhen.aliyuncs.com',
      };
      let res = await getFileUrl(params);
      // console.log(res);
      if (res.code == 200) {
        this.fileUrl = res.result;
      }
    },
    secede() {
      this.$parent.showDetails = false;
    },
  },
};
</script>

<style lang="less" scoped>
.details-page {
  padding: 20px 0;
  // max-height: 700px;
  overflow-y: auto;
  background-color: #fff;
  margin-bottom: 20px;
  .btns {
    display: flex;
    justify-content: space-between;
    width: 1200px;
    margin: 40px auto 0;

    button {
      width: 96px;
      height: 34px;
      font-size: 14px;
      color: #000;
      cursor: pointer;
      border-radius: 17px;
      border: 1px solid #999;
      background-color: #fff;
    }
  }
  .center {
    width: 1200px;
    margin: 50px auto 0;
    font-size: 14px;
    padding: 16px 0;
    box-sizing: border-box;
    background-color: #525659;
  }
}
</style>
