<template>
  <b-container fluid>
    <div v-show="!showDetails">
      <b-row>
        <b-col lg="12">
          <iq-card>
            <!-- <template v-slot:headerTitle>
              <h4 class="card-title">员工手册</h4>
            </template> -->
            <template v-slot:body>
              <div class="iq-search-bar">
                <p class="all-crumbs">文书资源 > 人力规章体系</p>
                <form action="#" class="" v-if="false">
                  <label for="validationDefault01">关键字</label>
                  <input
                    type="text"
                    class="text search-input"
                    style="cursor: pointer"
                    v-model="globalSearch"
                    placeholder="请输入关键词"
                  />
                  <el-button
                    class="btn mr-1 btn-primary rounded-pill seeAbout"
                    type="submit"
                    @click="getHRListInfo"
                  >
                    查询
                  </el-button>
                  <el-button
                    class="btn mr-1 btn-secondary rounded-pill reset"
                    type="submit"
                    @click="reset"
                  >
                    重置
                  </el-button>
                </form>
              </div>
              <!-- <div>
                <h4 class="card-title" style="display: inline-block">
                  已选条件：
                </h4>
                <span
                  class="selectedClassName"
                  v-for="items in checkVal"
                  :key="items"
                  >{{ items }}</span
                >
              </div> -->
            </template>
          </iq-card>
        </b-col>
        <b-col lg="3">
          <iq-card class="contract">
            <template v-slot:headerTitle>
              <h5 class="card-title" style="color: #837af3">员工手册</h5>
            </template>
            <template v-slot:body>
              <div class="tree-box">
            <el-tree
              :data="systemList"
              ref="tree"
              show-checkbox
              node-key="id"
              @check="handleCheckChange"
              :props="defaultProps"
              :default-expanded-keys="defaultShowNodes"
            >
            </el-tree>
              </div>
              <!-- <template
              v-for="(item, index) in contractCheckbox"
              style="background: white"
            >
              <b-checkbox
                v-model="item.isLocal"
                :disabled="item.disabled"
                name="check-button"
                inline
                :key="index"
                style="width: 100%"
                @change="changeBtn(item)"
              >
                {{ item.title }}
              </b-checkbox>
            </template> -->
            </template>
          </iq-card>
        </b-col>
        <b-col lg="9">
          <b-row class="roww">
            <template v-if="excelFileType.length != 0">
              <b-col
                lg="2"
                v-for="(txtType, index) in excelFileType"
                :key="index"
              >
                <div @click="toDetails(txtType)">
                  <iq-card style="padding: 0.5rem 0.5rem 0.2rem">
                    <div class="txtType">
                      <img src="../../assets/images/xmgl/file-ppt.png" alt="" />
                    </div>
                    <p class="txtTitle" :title="txtType.name">
                      {{ txtType.name }}
                    </p>
                  </iq-card>
                </div>
              </b-col>
            </template>
            <p v-else>暂无数据</p>
            <div class="btn" v-if="dataSize > excelFileType.length">
              <button @click="more">加载更多</button>
            </div>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <detailsPage v-if="showDetails" ref="details"></detailsPage>
  </b-container>
</template>

<script>
import { core } from "../../config/pluginInit";

// 引入函数
import { getHRcate, getFileList, getHRList } from "@/FackApi/api/admin";

import detailsPage from "./details/detailsPage2";
import { Loading } from "element-ui";
export default {
  name: "FormValidates",
  components: {
    detailsPage,
  },
  mounted() {
    core.index();
  },
  data() {
    return {
      contractCheckbox: [],
      checkVal: [],
      excelFileType: [],
      globalSearch: "",
      showSearch: false,
      showMenu: false,
      currentPage: 1,
      interPage: 20,
      dataSize: 0,
      showDetails: false,

      systemList: [],
      defaultProps: {
        children: "childs",
        label: "name",
        id: "id",
      },
      typeIdList: [],
      typeValList: [],
      currentPage: 1,
      interPage: 20,
      dataSize: 0,
    };
  },
  created() {
    this.getHRcateInfo();
  },
  methods: {
    // 接口请求方法
    async getHRcateInfo() {
      // let loadingInstance = Loading.service({ fullscreen: true });
      let res = await getHRcate();
      // console.log(res);
      if (res.code == 0) {
        this.systemList = res.result;
        this.defaultShowNodes = this.setNodeId(res.result,[]);
        let id = this.defaultShowNodes[this.defaultShowNodes.length -1];
        this.$nextTick(()=> {
          this.$refs.tree.setChecked(id,true,false);
          this.handleCheckChange();
        })
      }
    },
    setNodeId(data,arr) {
      arr.push(data[0].id);
      if (data[0].childs != null) {
        this.setNodeId(data[0].childs,arr);
      }
      return arr;
    },
    handleCheckChange() {
      let list = this.$refs.tree.getCheckedNodes();
      this.typeList = [];
      list.forEach((item) => {
        // if (item.subIsFolder == 0) {
          this.typeList.push(item.id);
        // }
      });
      this.excelFileType = [];
      this.dataSize = 0;
      this.currentPage = 1;
      this.getHRListInfo();
    },
    async getHRListInfo() {
      if (this.typeList.length == 0) return;
      let loadingInstance = Loading.service({ fullscreen: true });
      let params = {
        data: {
          parentIds: this.typeList,
          // keyword: this.keyVal,
        },
        pageIndex: this.currentPage,
        pageSize: this.interPage,
      };
      let res = await getHRList(params);
      loadingInstance.close();
      if (res.code == 0) {
        this.excelFileType = this.excelFileType.concat(res.result.items);
        this.dataSize = res.result.totalCount;
      }
    },
    more() {
      this.currentPage++;
      this.getHRListInfo();
    },
    reset() {
      this.globalSearch = "";
      this.dataSize = 0;
    },
    toDetails(item) {
      this.showDetails = true;
      this.$nextTick(() => {
        this.$refs.details.getHRDetailInfo(item);
      });
    },
  },
};
</script>
<style lang="less" scoped>
.iq-search-bar {
  // border-bottom: 1px solid #e7e7e7;
  // padding-bottom: 30px !important;
  // margin-bottom: 30px;
}
.container-fluid {
  margin-top: 20px;
}
.search-input {
  width: 300px !important;
  margin: 0 50px 0 10px !important;
  background: #eaf5ff !important;
}
.seeAbout {
  width: 100px;
  border-radius: 50px;
}
.reset {
  width: 100px;
  border-radius: 50px;
  background: #c0c4cc;
  margin-left: 10px !important;
}
.selectedClassName {
  padding: 5px 20px;
  margin-right: 10px;
  color: #837af3;
  font-size: 0.8em;
  display: inline-block;
  border-radius: 50px;
  background: linear-gradient(to right, #f0e4fd, #e6e4fd) !important;
}
.txtType {
  width: 100%;
  text-align: center;
  img {
    width: 5rem;
    margin: 10px auto;
  }
}
.txtTitle {
  // height: 2rem;
  // line-height: 2rem;
  font-size: 1em;
  color: #333333;
  padding: 0 1rem 0.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}
/deep/ .tree-box {
  // max-height: 490px;
  overflow-y: auto;
  .el-tree {
    .el-tree-node__content {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      .el-icon-caret-right {
        // padding: 0 0 6px 0;
        font-size: 16px;
        margin-left: 10px;
      }
      .el-checkbox {
        margin-left: -38px;
        margin-top: 8px;

        .el-checkbox__input {
          .el-checkbox__inner {
            font-size: 16px;
          }
            .el-checkbox__inner:hover {
              border: 1px solid #dcdfe6;
            }
        }
        .is-indeterminate {
          .el-checkbox__inner {
            background-color: #827af3;
            border-color: #827af3;
          }
        }
      }
        .is-checked {
          .is-checked {
            .el-checkbox__inner {
            background-color: #827af3;
            border-color: #827af3;
            }
          }
        }
      .el-tree-node__label {
        width: 160px;
        font-size: 14px;
        margin-left: 12px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
.roww {
  // max-height: 590px;
  padding-bottom: 30px;
  overflow-y: auto;
  .btn {
    width: 100%;
    button {
      height: 26px;
      border-radius: 14px;
      color: #a67af3;
      border: 1px solid #a67af3;
      background-color: #fff;
      padding: 0 20px;
      margin: 20px auto 0;
      cursor: pointer;
    }
  }
}
.all-crumbs {
  margin-bottom: 20px !important;
}
</style>
<style>
.contract {
  border-radius: 10px;
  overflow: hidden;
  padding: 0 !important;
  background: linear-gradient(to right, #f0e4fd, #e6e4fd) !important;
}
.contract .iq-card-body {
  background: white;
}
</style>
